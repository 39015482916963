import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import emailjs from "@emailjs/browser";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { app } from "../utils/server/firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";

import FileSelector from "../assets/components/FileSelector";
import { validateEmail } from "../utils/functions";
import { COUNTRIES, ESTADOS, STATES } from "../utils/constants";
import { navigate } from "gatsby";
import Footer from "../assets/components/Footer";
import Seo from "../assets/components/seo";
import Navbar from "../assets/components/Navbar";
import Heading from "../assets/components/Heading";

const db = getFirestore(app);

const SolicitudExpositores = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({});
  const [selected, setSelected] = useState();
  const nextStep = () => setActiveStep(activeStep + 1);
  const prevStep = () => setActiveStep(activeStep - 1);
  const theme = useTheme();

  const steps = ["Tus datos", "Datos de tu empresa", "Sube tus documentos"];

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo title="Solicitud de registro como expositor" />
      <Navbar />
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={4}
        bgcolor="transparent"
        minHeight="70vh"
        marginY={10}
        paddingX={4}
      >
        <Grid item>
          <Typography variant="h4" color={theme.palette.primary.main}>
            ¡Gracias por tu interés el Registro de solicitud de expositores ha
            concluido!
          </Typography>
        </Grid>
        {/*<Grid item xs={12}>
          <Heading label="Solicitud de registro de expositores" />
        </Grid>
        <Grid item container justifyContent="center" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              href="https://festivaldelchocolate.mx/Convocatoria.pdf"
              target="_blank"
              size="small"
            >
              Ver convocatoria
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              href="https://festivaldelchocolate.mx/Lineamientos.pdf"
              target="_blank"
              size="small"
            >
              Ver lineamientos
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, key) => (
              <Step key={key}>
                <StepLabel>{step}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item>
          {activeStep === 0 ? (
            <PersonalInformation
              nextStep={nextStep}
              data={data}
              setData={setData}
              selected={selected}
              setSelected={setSelected}
            />
          ) : null}

          {activeStep === 1 ? (
            <BusinessInformation
              data={data}
              nextStep={nextStep}
              prevStep={prevStep}
              setData={setData}
            />
          ) : null}

          {activeStep === 2 ? (
            <Documentation
              data={data}
              setData={setData}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          ) : null}
        </Grid>*/}
      </Grid>
      <Footer />
    </Container>
  );
};

const PersonalInformation = ({
  selected,
  setSelected,
  nextStep,
  data,
  setData,
}) => {
  const handleNext = async () => {
    if (
      !data?.nombre?.trim() ||
      !data?.rfc?.trim().toUpperCase() ||
      !data?.calle?.trim() ||
      !data?.ext ||
      !data?.colonia?.trim() ||
      !data?.cp ||
      !data?.municipio?.trim() ||
      !data?.estado?.trim() ||
      !data?.personalidadJuridica?.trim()
    ) {
      return toast.warning("Favor de llenar todos los campos");
    }

    try {
      const q = query(collection(db, "request"), where("rfc", "==", data.rfc));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length > 0)
        return toast.error("El RFC que ingresó ya se encuentra registrado");
    } catch (error) {
      return toast.warning("Ocurrio un error: " + error.message);
    }
    nextStep();
  };

  return (
    <Grid component="form" container spacing={2} maxWidth="md">
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="nombre"
          label="Nombre del representante legal"
          value={data?.nombre || ""}
          onChange={(e) => setData({ ...data, nombre: e.target.value })}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="rfc"
          label="RFC con homoclave"
          value={data?.rfc || ""}
          onChange={(e) => {
            setData({ ...data, rfc: e.target.value });
          }}
          inputProps={{ maxLength: 13 }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="calle"
          label="Calle"
          value={data?.calle || ""}
          onChange={(e) => setData({ ...data, calle: e.target.value })}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="ext"
          label="No. Exterior"
          value={data?.ext || ""}
          onChange={(e) => {
            const regex = /^[0-9\b]+$/;
            if (e.target.value == "" || regex.test(e.target.value)) {
              setData({ ...data, ext: e.target.value });
            }
          }}
          inputProps={{ maxLength: 5 }}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="colonia"
          label="Colonia"
          value={data?.colonia || ""}
          onChange={(e) => setData({ ...data, colonia: e.target.value })}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="cp"
          label="Código postal"
          value={data?.cp || ""}
          onChange={(e) => {
            const regex = /^[0-9\b]+$/;
            if (e.target.value == "" || regex.test(e.target.value)) {
              setData({ ...data, cp: e.target.value });
            }
          }}
          inputProps={{ maxLength: 5 }}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          select
          fullWidth
          variant="standard"
          label="País"
          color="primary"
          name="evento"
          required
          value={data?.pais || ""}
          onChange={(e) => {
            const filtered = STATES.filter(
              (item) => item.id_country === e.target.value
            );
            setSelected(filtered);
            setData({ ...data, pais: e.target.value });
          }}
        >
          <MenuItem value="" disabled selected>
            Seleccione un país
          </MenuItem>
          {COUNTRIES.map((e, key) => (
            <MenuItem key={key} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          fullWidth
          variant="standard"
          label="Estado"
          color="primary"
          name="evento"
          required
          value={data?.estado || ""}
          onChange={(e) => setData({ ...data, estado: e.target.value })}
        >
          <MenuItem value="" disabled selected>
            Seleccione un estado
          </MenuItem>
          {selected?.map((e, key) => (
            <MenuItem key={key} value={e?.name}>
              {e?.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          label="Municipio"
          name="municipio"
          value={data?.municipio || ""}
          onChange={(e) => setData({ ...data, municipio: e.target.value })}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="compañero1"
          label="Nombre completo del primer acompañante"
          value={data?.partner1 || ""}
          onChange={(e) => setData({ ...data, partner1: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="compañero2"
          label="Nombre completo del segundo acompañante"
          value={data?.partner2 || ""}
          onChange={(e) => setData({ ...data, partner2: e.target.value })}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl>
          <FormLabel id="personalidad-juridica">
            Personalidad jurídica
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="personalidad-juridica"
            value={data?.personalidadJuridica || ""}
            onChange={(e) => {
              setData({ ...data, personalidadJuridica: e.target.value });
            }}
          >
            <FormControlLabel
              value="fisica"
              control={<Radio />}
              label="Persona fisica"
            />
            <FormControlLabel
              value="moral"
              control={<Radio />}
              label="Persona moral"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Siguiente
        </Button>
      </Grid>
    </Grid>
  );
};

const BusinessInformation = ({ nextStep, prevStep, data, setData }) => {
  const [isHidden, setIsHidden] = useState(true);
  const giros = ["Agroindustrial", "Servicios", "Comercial", "Otro"];
  const espacios = [
    "Esencia Tabasco",
    "Instituto de Fomento a las Artesanias",
    "Sembrando Vida",
    "Secretaría de Turismo",
    "Independiente",
  ];
  const productos = [
    "Cacao y chocolate",
    "Derivados del cacao y chocolate",
    "Gastronómicos artesanales",
    "Alimentos",
    "Vinos, carnes y quesos",
    "Cerveza Artesanal",
    "Artesanias",
    "Otro",
  ];

  const handleNext = async () => {
    if (
      !data?.empresa?.trim() ||
      !data?.giro?.trim() ||
      !data?.operacion?.trim() ||
      !data?.telefono?.trim() ||
      !data?.email?.trim() ||
      !data?.productos ||
      !data?.espacioGestionado
    ) {
      return toast.warning("Favor de llenar todos los campos");
    } else if (!validateEmail(data?.email?.trim())) {
      return toast.info("Revise que el correo electrónico sea valido");
    }

    const folio = v4().split("-");
    setData({ ...data, folio: folio[1] + folio[2] });

    nextStep();
  };

  return (
    <Grid component="form" container spacing={2} maxWidth="md">
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="empresa"
          label="Nombre de la empresa"
          value={data?.empresa || ""}
          onChange={(e) => setData({ ...data, empresa: e.target.value })}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          style={{ textAlign: "left" }}
          select
          fullWidth
          label="Giro o Actividad *"
          color="primary"
          name="giro"
          value={data?.giro || ""}
          onChange={(e) => {
            setData({ ...data, giro: e.target.value });
          }}
        >
          <MenuItem value="" disabled selected>
            Seleccione un giro
          </MenuItem>
          {giros.map((giro, key) => (
            <MenuItem key={key} value={giro}>
              {giro}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="operacion"
          label="Años de operación"
          value={data?.operacion || ""}
          onChange={(e) => {
            const regex = /^[0-9\b]+$/;
            if (e.target.value == "" || regex.test(e.target.value)) {
              setData({ ...data, operacion: e.target.value });
            }
          }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="telefono"
          label="Teléfono"
          type="tel"
          value={data?.telefono || ""}
          onChange={(e) => {
            const regex = /^[0-9\b]+$/;
            if (e.target.value == "" || regex.test(e.target.value)) {
              setData({ ...data, telefono: e.target.value });
            }
          }}
          inputProps={{ maxLength: 10 }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          name="email"
          label="Correo electrónico"
          type="email"
          value={data?.email || ""}
          onChange={(e) => setData({ ...data, email: e.target.value })}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          id="facebook"
          label="Facebook"
          name="facebook"
          type="url"
          value={data?.facebook || ""}
          onChange={(e) => setData({ ...data, facebook: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          id="instagram"
          label="Instagram"
          name="instagram"
          type="url"
          value={data?.instagram || ""}
          onChange={(e) => setData({ ...data, instagram: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          id="twitter"
          label="X (Twitter)"
          name="twitter"
          type="url"
          value={data?.twitter || ""}
          onChange={(e) => setData({ ...data, twitter: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          style={{ textAlign: "left" }}
          select
          fullWidth
          label="Gestione mi espacio a través de: *"
          color="primary"
          name="espacio"
          value={data?.espacioGestionado || ""}
          onChange={(e) => {
            setData({ ...data, espacioGestionado: e.target.value });
            if (e.target.value === "Esencia Tabasco") {
              setIsHidden(false);
            } else {
              setIsHidden(true);
            }
          }}
        >
          <MenuItem value="" disabled selected>
            Seleccione una opción
          </MenuItem>
          {espacios.map((espacio, key) => (
            <MenuItem key={key} value={espacio}>
              {espacio}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6} display={isHidden ? "none" : "block"}>
        <TextField
          variant="standard"
          fullWidth
          name="marca"
          label="Nombre de la marca"
          value={data?.marca || ""}
          onChange={(e) => setData({ ...data, marca: e.target.value })}
          required
        />
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant="body1" color="GrayText">
            ¿Cuáles de los siguientes productos comercializa? *
          </Typography>
        </Grid>

        {productos.map((producto, key) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                color="primary"
                checked={
                  data?.productos &&
                  data?.productos.some((item) => item === producto)
                }
                onChange={() => {
                  const newProductos = data?.productos
                    ? [...data.productos]
                    : [];

                  const found = newProductos.findIndex(
                    (item) => item === producto
                  );
                  if (found !== -1) {
                    newProductos.splice(found, 1);
                  } else {
                    newProductos.push(producto);
                  }
                  setData({ ...data, productos: newProductos });
                }}
              />
            }
            label={producto}
          />
        ))}
      </Grid>

      <Grid item container justifyContent="center" spacing={2}>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={prevStep}>
            Volver
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleNext}>
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Documentation = ({ prevStep, data, setData }) => {
  const form = useRef();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const files = [
    {
      code: "csf",
      name: "Constancia de situación fiscal",
      tooltip:
        "La dirección de la Constancia de situación fiscal debe coincidir con la dirección del Comprobante de domicilio y debe ser mayor a 3 meses a la fecha del evento",
      type: ["moral", "fisica"],
    },
    {
      code: "cd",
      name: "Comprobante de domicilio",
      tooltip:
        "La dirección del Comprobante de domicilio debe coincidir con la dirección de la Constancia de situación fiscal y debe ser mayor a 3 meses a la fecha del evento",
      type: ["moral", "fisica"],
    },
    {
      code: "iov",
      name: "Identificación oficial vigente",
      tooltip: "",
      type: ["moral", "fisica"],
    },
    {
      code: "acv",
      name: "Acta constitutiva",
      tooltip: "",
      type: ["moral"],
    },
    {
      code: "pdnrl",
      name: "Poder notarial del representante legal",
      tooltip: "",
      type: ["moral"],
    },
  ];

  /* const iterablePromise = async (promises) => {
    let resolvedIterable = [];
    while (promises.length !== resolvedIterable.length) {
      resolvedIterable = await Promise.allSettled(promises);
    }
    return resolvedIterable;
  } */

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const documents = [];
      const storage = getStorage();
      const today = new Date(Date.now())
        .toLocaleDateString()
        .replace(/\//g, "");

      if (data.personalidadJuridica === "moral") {
        if (5 !== selected.length)
          return toast.error("Todos los documentos son obligatorios");
      } else if (data.personalidadJuridica === "fisica") {
        if (3 !== selected.length)
          return toast.error("Todos los documentos son obligatorios");
      }

      const uploadPromises = selected.map(async (item) => {
        const documentRef = ref(
          storage,
          `docs/${data.rfc.toUpperCase()}/${item.code}_${today}_${v4()}.pdf`
        );
        const snapshot = await uploadBytes(documentRef, item.file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        documents.push(downloadURL);
      });

      await Promise.all(uploadPromises);

      const docsRef = collection(db, "request");
      await addDoc(docsRef, {
        partner1: data?.partner1 || " ",
        partner2: data?.partner2 || " ",
        calle: data?.calle,
        colonia: data?.colonia,
        cp: data?.cp,
        email: data?.email,
        empresa: data?.empresa,
        pais: data?.pais,
        estado: data?.estado,
        facebook: data?.facebook || " ",
        giro: data?.giro,
        instagram: data?.instagram || " ",
        marca: data?.marca || " ",
        municipio: data?.municipio,
        nombre: data?.nombre,
        operacion: data?.operacion,
        productos: data?.productos,
        rfc: data?.rfc?.toUpperCase(),
        telefono: data?.telefono,
        twitter: data?.twitter || " ",
        createAt: new Date(),
        observaciones: "",
        status: "En proceso de revisión",
        escencia: data?.escencia || false,
        folio: data?.folio,
        personalidadJuridica: data?.personalidadJuridica || "",
        isDisabled: false,
        documents,
      });

      toast.success("Soliciud creada. Su folio es: " + data?.folio);

      await emailjs.send(
        "service_twbewbk",
        "template_lz21yfp",
        {
          empresa: data?.empresa,
          folio: data?.folio,
          email: data?.email,
        },
        "zedoThcsPAXIA-LIv"
      );
      navigate("/");
    } catch (error) {
      console.log(error);
      toast.error(
        "Hubo un problema al crear tu solicitud. Recargue e intente de nuevo."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (file, type) => {
    const selectedDocs = selected;
    const found = selectedDocs.findIndex((doc) => doc.type === type.name);
    if (found !== -1) {
      // Document was already in the array, so we remove it
      selectedDocs.splice(found, 1);
    }
    selectedDocs.push({ file, type: type.name, code: type.code });
    setSelected([...selectedDocs]);
  };

  const getFileSelectorValue = (name) => {
    if (name) return selected.find((doc) => doc.name === name);
    return undefined;
  };
  return (
    <Grid
      container
      spacing={2}
      maxWidth="md"
      component="form"
      ref={form}
      onSubmit={handleSubmit}
    >
      {loading ? (
        <Grid container flexDirection="column">
          <Grid item>
            <CircularProgress color="primary" size={120} />
          </Grid>
          <Grid item>
            <Typography variant="h6">Creando solicitud...</Typography>
          </Grid>
        </Grid>
      ) : null}

      {/**Contenedor General de inputs  */}
      {!loading ? (
        <>
          <Grid item container columnSpacing={{ sm: 2 }}>
            {files
              /*   .filter((file) => {
              const rfc = data.rfc.length;
              if (rfc === 12) return true;
              return file.maxDigitsRFC === rfc;
            }) */
              .filter((file) => {
                return file.type.some(
                  (type) => type === data?.personalidadJuridica
                );
              })
              .map((file, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <FileSelector
                    label={file.name}
                    onChange={(item) => handleChange(item, file)}
                    value={getFileSelectorValue(file.name)}
                    tooltip={file.tooltip}
                  />
                </Grid>
              ))}
            <Grid item xs={12}>
              <Typography fontWeight="bold">
                Al continuar, usted acepta los{" "}
                <a
                  href="https://festivaldelchocolate.mx/Lineamientos.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  lineamientos
                </a>{" "}
                para el funcionamiento del Festival del Chocolate 2024
              </Typography>
            </Grid>
          </Grid>

          {data?.name ? (
            <input type="hidden" name="name" value={data?.name} />
          ) : null}
          {data?.empresa ? (
            <input type="hidden" name="empresa" value={data?.empresa} />
          ) : null}
          {data?.folio ? (
            <input type="hidden" name="folio" value={data?.folio} />
          ) : null}
          {data?.email ? (
            <input type="hidden" name="to_email" value={data?.email} />
          ) : null}
        </>
      ) : null}

      <Grid item container flexDirection="row" spacing={2}>
        {prevStep ? (
          <Grid item>
            <Button disabled={loading} variant="outlined" onClick={prevStep}>
              Regresar
            </Button>
          </Grid>
        ) : null}
        <Grid item>
          <Button disabled={loading} variant="contained" type="submit">
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SolicitudExpositores;
