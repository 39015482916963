import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

const Heading = ({ label, isWhite, variant }) => {
  if (variant === "outlined")
    return (
      <>
        <Typography
          variant="h3"
          textAlign="center"
          color={isWhite ? "#FDF6E8" : "#6c3f37"}
          paddingY={1}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {label}
        </Typography>
      </>
    );

  return (
    <>
      <Divider
        style={{
          maxWidth: 630,
          backgroundColor: isWhite ? "#FDF6E8" : "#a57a3e",
          height: 2,
          margin: "0 auto",
        }}
      />

      <Typography
        variant="h1"
        textAlign="center"
        color={isWhite ? "#FDF6E8" : "#6c3f37"}
        paddingY={1}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {label}
      </Typography>

      <Divider
        style={{
          maxWidth: 630,
          backgroundColor: isWhite ? "#FDF6E8" : "#a57a3e",
          height: 2,
          margin: "0 auto",
        }}
      />
    </>
  );
};

export default Heading;
